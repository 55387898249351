import { css, createGlobalStyle } from "styled-components";

const fontList = [
  ["regular", 500],
  ["medium", 600],
  ["bold", 700],
];

function getTalPublicAssetsPath() {
  return typeof process === "undefined"
    ? window["oetal"].env.talPublicAssetsPath
    : `${process.env["TAL_PUBLIC_ASSETS_ORIGIN"]}/static/tal-client/`;
}

const GorditaFontStyles = () => {
  return fontList.map(([style, weight]) => {
    const fontPrefix = `gordita${style}`;
    const fontName = `${fontPrefix}-webfont`;
    return css`
      @font-face {
        font-family: "gordita";
        src: url("${getTalPublicAssetsPath()}fonts/gordita/${fontName}.woff2")
            format("woff2"),
          url("${getTalPublicAssetsPath()}fonts/gordita/${fontName}.woff")
            format("woff");
        font-weight: ${weight};
        font-style: ${style};
      }
    `;
  });
};

export const FontStyles = createGlobalStyle`
  ${GorditaFontStyles()}

  @font-face {
    font-family: "originicons";
    src: url('${getTalPublicAssetsPath()}fonts/originicons/originicons.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }
`;

export const ResetStyles = createGlobalStyle`
  * {
    font-family: 'gordita', Arial, sans-serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }
  html, body, #root {
    height: 100%;
  }
  body {
    margin: 0;
    padding: 0;
  }
  body, h1, h2, h3, p, span {
    font-weight: normal;
  }
  #root {
    display: flex;
    flex-direction: column;
  }
  .MuiDialog-paper {
    -webkit-transform: translate3d(0, 0, 0);
    will-change: transform;
  }
  [data-id="account-summary"], [data-id="lpg-widget"], [data-id="broadband-widget"] {
    -webkit-transform: translate3d(0, 0, 0);
    will-change: transform;
  }
  /*
   * We recommend to apply this class on distorted animated elements in android webviews, eg. MuiDialog container.
   * Explanation: translate3d(0, 0, 0) can trigger hardware acceleration in Android WebView,
   * even if the WebView's hardware acceleration is set to false at the system level.
   */
  .accelerated-element {
    -webkit-transform: translate3d(0, 0, 0);
    will-change: transform;
  }
`;

export const IconFontStyles = createGlobalStyle`
  @font-face{
    font-family: "Material Icons";
    src:
        url('${getTalPublicAssetsPath()}fonts/materialIcons/MaterialIcons-Regular.woff2') format('woff2'),
        url('${getTalPublicAssetsPath()}fonts/materialIcons/MaterialIcons-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }

  .md-icon {
    font-family: 'Material Icons', Arial, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;

    vertical-align: bottom;
    margin-right: 5px;

    direction: ltr;
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
    /* Support for IE. */
    font-feature-settings: 'liga';
  }
`;

/*
 * Braze styling:
 * ensure Modals include Gordita font,
 * round and style buttons.
 */
export const BrazeStyles = createGlobalStyle`
  #braze-oe .ab-message-header,
  #braze-oe .ab-message-text {
    font-family: gordita, Arial, sans-serif;
    text-align: left;
    font-weight: 400;
  }

  #braze-oe .ab-message-header {
    font-size: 20px;
    margin-bottom: 32px;
    font-weight: 600;
  }

  #braze-oe .ab-in-app-message.ab-background.ab-modal-interactions.ab-modal {
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 64px);
  }
  #braze-oe .ab-message-text.ab-with-buttons {
    white-space: wrap !important;
    margin-bottom: 0;
  }

  #braze-oe .ab-message-buttons {
    padding: 32px 24px;
    text-align: center; /* Centers the buttons */
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  #braze-oe .ab-message-button {
    padding: 12px 24px;
    border-radius: 24px;
    font: 600 16px/24px gordita, Arial, sans-serif;
    border: none !important;
    width: 100%;
    height: auto;
    background-color: #ec0000; /* Red button */
    color: #FFFFFF;
  }

  #braze-oe .ab-message-button:hover {
    background-color: #c60000 !important;
    opacity: 1;
  }

  #braze-oe .ab-message-button:nth-child(2) {
    background-color: #FFFFFF; /* White button */
    color: #ec0000;
    border: 1px solid #ec0000 !important;
  }
  #braze-oe .ab-message-button:nth-child(2):hover {
    background-color: rgba(236, 0, 0, 0.08) !important;
  }

  @media (max-width: 600px) {
    #braze-oe .ab-in-app-message.ab-slideup {
      max-width: calc(100% - 52px);
      min-width: calc(100% - 52px);
      margin: 16px;
    }
  }
`;
