import {
  IPropertyWithIds,
  IService,
  IUserContext,
} from "@origin-digital/event-dispatcher";

const isActive = (service: IService) => service.serviceStatus !== "CLOSED";

export const getAllPropertiesForUser = (user?: IUserContext) => {
  return (
    user?.accounts.reduce(
      (acc: [IPropertyWithIds[], IPropertyWithIds[]], a) => {
        a.services.map((service) => {
          const propertyWithIds = {
            ...service.property,
            accountIds: [a.id],
            serviceIds: [service.id],
          };
          const array = isActive(service) ? acc[0] : acc[1];
          const existingIndex = array.findIndex(
            (p) => p.id === service.property.id
          );
          if (existingIndex >= 0) {
            array[existingIndex].accountIds.push(a.id);
            array[existingIndex].serviceIds.push(service.id);
          } else {
            array.push(propertyWithIds);
          }
          return service;
        });
        return acc;
      },
      [[], []]
    ) ?? [[], []]
  );
};
