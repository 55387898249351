import React from "react";
import styled from "styled-components";
import { BREAK_POINTS } from "../../consts/style";
import { lighten } from "@mui/material";

const ParentDiv = styled.div<{ type?: string }>`
  display: flex;
  font-family: "gordita", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.7em;
  background-color: ${(props) =>
    props.type === "warning"
      ? lighten(props.theme.palette.warning.main, 0.92)
      : lighten(props.theme.palette.info.main, 0.92)};
  color: ${(props) => props.theme.palette.grey[500]};
  & a {
    color: ${(props) =>
      props.type === "warning" ? "#7b0000" : "rgb(80, 80, 80)"};
  }
`;

const Container = styled.div`
  display: block;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  @media (min-width: ${BREAK_POINTS.MIN.LG}) {
    width: 1170px;
  }
`;

const WrapperDiv = styled.div`
  margin: 16px 4px;
  display: flex;
`;

const LeftDiv = styled.div`
  padding-right: 12px;
`;

const RightDiv = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const TitleDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const NotificationHeader = styled.h4`
  text-transform: none;
  margin: 0;
  font-weight: 600;
`;

const Paragraph = styled.span`
  margin: 0;
`;

export const CloseButton = styled.button`
  margin: 0;
  border: 0;
  font-size: 24px;
  padding: 0;
  background: transparent;
  align-self: flex-start;
  &:hover {
    cursor: pointer;
  }
`;

const SrOnlySpan = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
`;

const IconSpan = styled.span`
  position: relative;
`;

const Icon24px = styled.svg<{ type?: string; fill?: string }>`
  width: 24px;
  height: 24px;
  /* stylelint-disable-next-line */
  fill: ${(props) =>
    props.fill
      ? props.theme.palette[props.fill]
      : props.type === "warning"
      ? props.theme.palette.warning.main
      : props.theme.palette.info.main};
  viewbox: "0 0 24 24";
`;

const CloseIconSvg = () => (
  <Icon24px fill="grey500" fontWeight={400}>
    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
  </Icon24px>
);

const WarningIconSvg = () => (
  <Icon24px type="warning">
    <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
  </Icon24px>
);
const InfoIconSvg = () => (
  <Icon24px type="info">
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
  </Icon24px>
);

export interface IBannerView {
  index: number;
  bannerId?: number;
  type?: string;
  heading: string;
  message: string;
  onCloseButtonClick: () => void;
  closeBtnDataId: string;
}

const CloseIconButton = ({
  closeBtnDataId,
  onCloseButtonClick,
}: Partial<IBannerView>) => (
  <CloseButton
    title="Close"
    data-id={closeBtnDataId}
    onClick={onCloseButtonClick}
  >
    <SrOnlySpan>Close</SrOnlySpan>
    <IconSpan>
      <CloseIconSvg />
    </IconSpan>
  </CloseButton>
);

export const BannerView = ({
  index,
  bannerId,
  type,
  heading,
  message,
  onCloseButtonClick,
  closeBtnDataId,
}: IBannerView) => {
  return (
    <ParentDiv
      data-id={`banner-${bannerId}`}
      type={type}
      className="accelerated-element"
    >
      <Container>
        <WrapperDiv>
          <LeftDiv>
            <SrOnlySpan>Information</SrOnlySpan>
            {type === "warning" ? <WarningIconSvg /> : <InfoIconSvg />}
          </LeftDiv>
          <RightDiv>
            <TitleDiv>
              <NotificationHeader
                dangerouslySetInnerHTML={{ __html: heading }}
              />
              {index === 0 && (
                <CloseIconButton
                  type={type}
                  onCloseButtonClick={onCloseButtonClick}
                  closeBtnDataId={closeBtnDataId}
                />
              )}
            </TitleDiv>
            <div>
              <Paragraph dangerouslySetInnerHTML={{ __html: message }} />
            </div>
          </RightDiv>
        </WrapperDiv>
      </Container>
    </ParentDiv>
  );
};
